import React from 'react';
import Layout from '../components/Layout';

const NotFound: React.FunctionComponent<{}> = props => {
  return (
    <Layout title="Page Not Found">
      <div className="flex-grow justify-center">
        <div className="container mx-auto">
          <h1 className="text-2xl ">404 Page Not Found</h1>
          <p className="py-4">
            Oops! The page you are looking for has been removed or relocated.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
